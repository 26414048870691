import apiClient from '../util/axiosInstance';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useMessage } from "../Components/MessageContext.js";

export const ProfilePage = () => {
    const [firstNameValue, setFirstNameValue] = useState('');
    const [lastNameValue, setLastNameValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [confirmPasswordValue, setConfirmPasswordValue] = useState('');


    const { showMessage } = useMessage();

    const navigate = useNavigate();

    useEffect( () => {
        const loadAdminData = async () => {
            try {
                const response = await apiClient.get('/api/getadmindata');  
                const adminData = response.data;
                setFirstNameValue(adminData.firstName || '');
                setLastNameValue(adminData.lastName || '');
                setEmailValue(adminData.email);
            } catch {   
                showMessage('Cannot fetch profile data!')
            }
        }
        loadAdminData();
    }, [])


    const onSaveClicked = async () => {
        try {
            await apiClient.put('/api/updateadmindata', {
                firstName: firstNameValue,
                lastName: lastNameValue,
                email: emailValue,
                password: passwordValue,
            });
            // const newAdmin = response.data;
            navigate('/');
            showMessage('Profile updated successfully!');
        } catch (error) {
            if (error.response.status === 403) {
                // console.log('Authentication failed.');
                showMessage('Authentication failed.');
                navigate('/login')
            } else {
                // console.log('Internal Server Error!');
                showMessage('Internal Server Error!');
            }
        }
    }

    return (
        <>
            <h2>Your Profile</h2>
            <dl>
                <dt className="col-12">First Name</dt>
                <dd className="col-12">
                    <input
                        type="text"
                        value={firstNameValue}
                        placeholder="Enter First Name"
                        onChange={e => setFirstNameValue(e.target.value)}
                    />
                </dd>
            </dl>
            <dl>
                <dt className="col-12">Last Name</dt>
                <dd className="col-12">
                    <input
                        type="text"
                        value={lastNameValue}
                        placeholder="Enter Last Name"
                        onChange={e => setLastNameValue(e.target.value)}
                    />
                </dd>
            </dl>
            <dl>
                <dt className="col-12">Email</dt>
                <dd className="col-12">
                    <input
                        type="text"
                        value={emailValue}
                        placeholder="Enter your email address"
                        onChange={e => setEmailValue(e.target.value)}
                    />
                </dd>
            </dl>
            <dl>
                <dt className="col-12">Change Password (leave empty if you do not wish to change)</dt>
                <dd className="col-12">
                    <input
                        type="password"
                        value={passwordValue}
                        placeholder="Enter New Password"
                        onChange={e => setPasswordValue(e.target.value)}
                    />
                </dd>
                <dd className="col-12">    
                    <input
                        type="password" 
                        value={confirmPasswordValue}
                        placeholder="Confirm New Password"
                        onChange={e => setConfirmPasswordValue(e.target.value)}
                    />
                </dd>
            </dl>
            <dl>
                <dd className="col-12">
                    <button                 
                        disabled={ (passwordValue !== confirmPasswordValue) || !emailValue}
                        onClick={onSaveClicked}>
                            Save
                    </button>
                </dd>
            </dl>
        </>
    )
}

