import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../util/axiosInstance';
import Cookies from 'js-cookie';
import { useMessage } from '../Components/MessageContext';
import axios from 'axios';

export const LogInPage = ({ onLogin, user }) => {
    const [isLoggingIn, setIsLoggingIn] = useState(false);  // To disable the login button during login
    const [errorMessage, setErrorMessage] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const { showMessage } = useMessage();
    const navigate = useNavigate();
    console.log('loginPage');
    // useEffect( () => {
    //     const checkServerStatus = async () => {
    //         try {
    //             const response = await axios.get('/api/test');
    //             showMessage(response.status);
    //         } catch {
    //             showMessage('Server unavailable.')
    //         }
    //     }
    //     checkServerStatus();
    // }, [])
  
    // useEffect( () => {
    //     const checkLogin = () => {
    //         if (user) {
    //             console.log('user is: ', user);
    //             navigate('/');
    //         }
    //     }    
    //     checkLogin();
    // }, [])
  
    // useEffect(() => {
    //     const token = Cookies.get('authToken');
        
        
    //     // Check if the user is already authenticated
    //     if (token && token !== 'undefined' && !user) {
    //         onLogin(token); // Set the user state with the token if not already set
    //         navigate('/');  // Redirect to home
    //     }
    // }, [user, navigate, onLogin]);



    const onLogInClicked = async (event) => {
        event.preventDefault();
        setIsLoggingIn(true);
        try {
            const response = await apiClient.post('/api/login', {
                email: emailValue,
                password: passwordValue,
            });
            showMessage(response.status);
            console.log(response);
            const { token } = await response.data;
            if (token && (token !== 'undefined')) {
                showMessage(`Login successful. User is ${token}`);
                onLogin(token);
                setIsLoggingIn(false);
                navigate('/'        );  // Navigate after login state is updated
            } else {
                showMessage('Login Failed...');
            }
        } catch (e) {
            setErrorMessage(e.message);
            setIsLoggingIn(false);
        }
    };
    

    return (
        <>
            <h2>Log In</h2>
            {errorMessage && <div className="fail">{errorMessage}</div>}
            <dl>
                <dd className='col-12'>
                <input
                    type='text'
                    value={emailValue}
                    onChange={e => setEmailValue(e.target.value)}
                    placeholder="someone@gmail.com" />
                </dd>
            </dl>
            <dl className='col-12'>
                <dd className='col-12'>
                <input
                    type="password"
                    value={passwordValue}
                    onChange={e => setPasswordValue(e.target.value)}
                    placeholder="password" />
                </dd>
            </dl>
            <hr />
            <dl>
                <dd className='col-12'>
            <button
                disabled={!emailValue || !passwordValue || isLoggingIn}
                onClick={onLogInClicked}>Log In</button>
                </dd>
            </dl>
            
            <dl className='col-12'>
                <dd className='col-12'>
                    <button onClick={() => navigate('/forgot-password')}>Forgot your password?</button>
                </dd>
            </dl>
            <dl>
                <dd className='col-12'>
                    <button onClick={() => navigate('/signup')}>Don't have an account? Sign Up</button>
                </dd>
            </dl>
        </>
    );
}