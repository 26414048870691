import { registerPlugin } from '@capacitor/core';
import { WifiPluginWeb } from './web/WifiPluginWeb.ts';
import type { WifiPluginPlugin } from './definitions.ts';

// Register the WifiPlugin
// const WifiPlugin = registerPlugin<WifiPluginPlugin>('WifiPlugin');
const WifiPlugin = registerPlugin('WifiPlugin', {
  web: () => import('./web/WifiPluginWeb.ts').then(m => new m.WifiPluginWeb()),
});
export { WifiPlugin };
