import { WifiPlugin } from '../plugins/wifi-plugin/index.ts'; // Path to your index.ts file
import apiClient from './axiosInstance.js';
import checkWifiAndSendRequest from './checkWifi.js';

// Function to listen for Wi-Fi events
const setupWifiNotifier = async () => {
    const sendConnect = async () => {
        const ssid = await checkWifiAndSendRequest();
        const response = await apiClient.post('/api/connected',{
            ssid: ssid,
            devName: 'Arash Phone'
        });
    }

    const sendDisconnect = async () => {
        const response = await apiClient.post('/api/disconnected',{
            devName: 'Arash Phone   ',
        });
    }

    WifiPlugin.addListener('wifiConnected', (info) => {
        console.log('Wi-Fi connected:', info.ssid);
        // Send the SSID to the server
        sendConnect();

        // fetch('/api/sendSSID', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({ ssid: info.ssid }),
        // })
        // .then(response => {
        //     if (response.ok) {
        //         console.log('SSID sent to the server');
        //     } else {
        //         console.error('Failed to send SSID to the server');
        //     }
        // })
        // .catch(err => console.error('Error sending SSID:', err));
    });

    WifiPlugin.addListener('wifiDisconnected', () => {
    console.log('Wi-Fi disconnected');
    sendDisconnect();
    // Notify the server that Wi-Fi is disconnected
//     fetch('/api/wifiDisconnected', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ message: 'Wi-Fi disconnected' }),
//     })
//     .then(response => {
//       if (response.ok) {
//         console.log('Wi-Fi disconnected sent to the server');
//       } else {
//         console.error('Failed to notify disconnection');
//       }
//     })
//     .catch(err => console.error('Error sending disconnection:', err));
  });
};

export default setupWifiNotifier;
// // Run this function when your component mounts
// useEffect(() => {
//   setupWifiNotifier();
// }, []);
