import { useNavigate } from 'react-router-dom';
import { useToken } from '../auth/useToken';
import { useEffect } from 'react';
import setCookie from '../auth/setCookie';

const LogoutPage = () => {
    const [, setToken] = useToken();
    const navigate = useNavigate();

    useEffect(() => {
        setToken('');
        setCookie("authToken","",0);
        navigate('/login');
      }, [navigate, setToken]);


}

export default LogoutPage;