export const cameraDataLabels = {
    username: "Username",
    camera_ip: "Camera IP",
    password: "Password",
    camera_port: "Connection Port",
    stream_enabled: "Enable Remote Streaming",
    stream_port: "Streaming Port",
    width: "Vieo Recording Width",
    height: "Vieo Recording Height",
    frame_rate: "Frame Rate (fps)",
    motion_threshold: "Motion Detection Threshold",
    motion_noise: "Motion Detection Noise",
    event_gap: "End Recording After Motion Ends (sec)",
    pre_capture: "The number of Pictures Before Motion Starts",
    post_capture: "Number of Frames After Motion Ends",
    movie_quality: "Video Quality (%)",
    movie_max_time: "Maximum Length of Movie (sec)",
};

export const cameraDataProhibitedFields = ["camera_name", "camera_id"];

export const adminDataLabels = {
  email: "Email Address",
  firstName: "First Name",
  lastName: "Last Name",
}