import apiClient from '../util/axiosInstance';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useMessage } from "../Components/MessageContext.js";
import { useParams } from "react-router-dom";

export const EditDevice = () => {
    const { deviceId } = useParams();
    const [deviceNameValue, setDeviceNameValue] = useState('');
    const [deviceIPValue, setDeviceIPValue] = useState('');

    const { showMessage } = useMessage();

    const navigate = useNavigate();

    useEffect ( () => {
        const loadDeviceData = async () => {
            try {
                const response = await apiClient.get(`/api/getclientdatabyid/${deviceId}`);
                const deviceData = response.data;
                setDeviceNameValue(deviceData.client_name);
                setDeviceIPValue(deviceData.client_ip);
            } catch {   
                showMessage('Error fetching data');
    
            }
        }
        loadDeviceData();    
    }, [])


    const onSaveClicked = async () => {
        try {
            const response = await apiClient.put(`/api/updateclientdata/${deviceId}`, {
                client_name: deviceNameValue,
                client_ip: deviceIPValue,
            });
            const postedDevice = response.data;             
            // setDeviceNameValue(postedDevice.client_name);
            // setDeviceIPValue(postedDevice.cliet_ip);
            navigate('/devices');
            showMessage('Device updated successfully!');
        } catch (error) {
            if (error.response.status === 403) {
                showMessage('Authentication failed.');
                navigate('/login')
            } else if  (error.response.status === 409) {
                showMessage("Camera IP alerady exist!")
            } else {
                showMessage('Internal Server Error!');
            }
        }
    }

    return (
        <>
            <h2>Add a New Device</h2>
            <dl>
                <dt className="col-lg-5 col-md-5 col-sm-6 col-12">Device Name</dt>
                <dd className="col-lg-7 col-md-7 col-sm-6 col-12">
                    <input
                    type="text" // Dynamically set input type based on key
                        value={deviceNameValue}
                        placeholder="Enter Device Name"
                        onChange={e => setDeviceNameValue(e.target.value)}
                    />
                </dd>
            </dl>
            <dl>
                <dt className="col-lg-5 col-md-5 col-sm-6 col-12">Device Local IP Address</dt>
                <dd className="col-lg-7 col-md-7 col-sm-6 col-12">
                    <input
                    type="text" // Dynamically set input type based on key
                        value={deviceIPValue}
                        placeholder="ex.: 192.168.1.15"
                        onChange={e => setDeviceIPValue(e.target.value)}
                    />
                </dd>
            </dl>
            <button                 
                disabled={!deviceIPValue || !deviceNameValue}
                onClick={onSaveClicked}>Save
            </button>
        </>
    )
}
