import Cookies from "js-cookie";
import getPayloadFromToken from "../util/getPayloadFromToken";
import { Preferences } from '@capacitor/preferences';

async function getAuthToken() {
    const { value: token } = await Preferences.get({ key: 'authToken' });
    return token;
}
// Move token retrieval outside to avoid redefining on every call
const getToken = () => {
    try {
        return Cookies.get("authToken") || '';  // Safe default if no token
    } catch (error) {
        console.error("Error fetching the token", error);
        return '';
    }
};

const getUser = async () => {
    const token = await getAuthToken();  // Get token
    // console.log('token is: ',   token);
    if (token && token !== 'undefined') {
        return getPayloadFromToken(token);  // Decode and return the payload
    }
    return null;  // Return null if no valid token
};

export default getUser;
