import apiClient from './axiosInstance';

const armCamera = async () => {
    try {
        const response = await apiClient.get('/api/arm');
        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (err) {
        console.log(err);
        return false;
    }
}

export default armCamera;