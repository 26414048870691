import { useMessage } from './MessageContext';

export const GlobalMessage = () => {
    const { message } = useMessage();

    if (!message) return null;

    return (
        <div className="global-message">
            {message}
        </div>
    );
};
