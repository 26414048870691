import { Route, Routes } from 'react-router-dom';
import { EmailVerificationLandingPage } from './pages/EmailVerificationLandingPage.js';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage.js';
import { SignUpPage } from './pages/SignUpPage.js';
import { Home } from './pages/Home.js';
import { PasswordResetLandingPage } from './pages/PasswordResetLandingPage.js';
import { PleaseVerifyEmailPage } from './pages/PleaseVerifyEmailPage.js';
import { PrivateRoute } from './auth/PrivateRoute.js';
import Setting from './pages/Setting.js';
import { StreamPage } from './pages/StreamPage.js';
import LogoutPage from './pages/LogoutPage.js';
import { DeviceList } from './pages/DeviceListPage.js';
import { AddDevice } from './pages/AddDevice.js';
import { LogInPage } from './pages/LogInPage.js';
import { ProfilePage } from './pages/ProfilePage.js';
import { EditDevice } from './pages/EditDevice.js';
import { ServerCheckRoute } from './util/serverCheckRoute.js';
import ServerNotAvailable from './pages/serverNotAvailable.js';
import { useEffect, useState } from 'react';
import checkServerStatus from './util/checkServerStatus.js';


export const WebSiteRoutes = ({ onLogin, user }) => {    
    const [serverStatus, setServerStatus] = useState(false);
    useEffect( () => {
        const fetchStatus = async () => {
            try {
                const result = await checkServerStatus();
                setServerStatus(result);
            } catch {
                setServerStatus(false);
            }
        }
        fetchStatus();
    }, [])

    return (
            <Routes>
                {/* <Route element={<ServerCheckRoute serverStatus={ serverStatus} setServerStatus={setServerStatus} />}>  */}
                    <Route path="/verify-email/:verificationString" element={<EmailVerificationLandingPage />} />
                    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                    <Route path="/reset-password/:passwordResetCode" element={<PasswordResetLandingPage />} />
                    <Route path="/please-verify" element={<PleaseVerifyEmailPage />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route path="/logout" element={<LogoutPage />} />
                    <Route path="/login" element={<LogInPage onLogin={onLogin} user={user}/>} />
                    {/* Protected Routes */}
                    <Route element={<PrivateRoute user={user}/>}>
                        <Route path="/" element={<Home />} />
                        <Route path="/setting/:cameraId" element={<Setting />} />
                        <Route path="/stream/:cameraId" element={<StreamPage />} />
                        <Route path="/adddevice" element={<AddDevice />} />
                        <Route path="/devices" element={<DeviceList />} />
                        <Route path="/profile" element={<ProfilePage />}/>
                        <Route path="/editdevice/:deviceId" element={<EditDevice />}/>
                    </Route>
                {/* </Route> */}
                <Route path="/server-not-available" element={<ServerNotAvailable />} />
            </Routes>
    );
}