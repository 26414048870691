import apiClient from '../util/axiosInstance';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import armCamera from "../util/armCamera";
import disarmCamera from "../util/disarmCamera";
import sleep from "../util/sleep";
import { useNavigate } from 'react-router-dom';
import { SettingIcon } from "../svg/settingIcon.js";
import { useMessage } from "../Components/MessageContext.js";
import { RefreshIcon } from '../svg/refreshIcon.js';
import checkWifiAndSendRequest from '../util/checkWifi.js';

export const Home = () => {
    const [cameraData, setCameraData] = useState([]);
    const [settingLink, setSettingLink] = useState('');
    const [loading, setLoading] = useState(true);
    const [isCameraReady, setIsCameraReady] = useState(false);
    const [isMotionRunning, setIsMotionRunning] = useState(false);
    const [updateStates, setUpdateStates] = useState(false);
    const [executing, setExecuting] = useState(false);
    const [refreshState, setRefreshState] = useState(false);
    const [serverStatus, setServerStatus] = useState(false);


    const navigate = useNavigate();
    const { showMessage } = useMessage();
    const streamUrl = `/stream/${cameraData.camera_id}`;

    useEffect( () => {
        const fetchServerStatus = async () => {
            try {
                const respone = await apiClient.get('/api/test');
                if (respone.status === 200) {
                    setServerStatus(true);
                } else {
                    setServerStatus(false);
                }
            } catch {
                setServerStatus(false);
                cameraData.camera_name = 'No connection';
            }
        }
        fetchServerStatus();
    }, [refreshState]);

    useEffect( () => {
        const loadCameraData = async () => {
            try {
                const response = await apiClient.get('/api/getcameradata');
                const cameraData = await response.data;
                setCameraData(cameraData);  
                const path = `/setting/${cameraData.camera_id}`;
                setSettingLink(path);
                setLoading(false);
            } catch (error) {
                console.log('Error connecting the camera.');
                if (typeof error.respone !== 'undefined') {
                    if (error.response.status === 403) {
                        showMessage('Authenticaion failed!')
                        navigate('/login')
                    }
                }
                return false;
            }
        }
        loadCameraData();
    }, [navigate, refreshState]);

    useEffect( () => {
        const cameraStatus = async () => {
            try {
                const response = await apiClient.get('/api/iscameraready');
                const status = response.data;
                setIsCameraReady(status);
            } catch (error) {
                setIsCameraReady(false);
                if (typeof error.respone !== 'undefined') {
                    if (error.response.status === 403) {
                        showMessage('Authentication failed.');
                        navigate('/login')
                    }
                    console.log('Camera status unknown');
                }
            }
        }
        cameraStatus();
    }, [navigate, refreshState])

    useEffect( () => {
        const motionStatus = async () => {
            try {
                const response = await apiClient.get('/api/getmotionstatus');
                const status = response.data;
                setIsMotionRunning(status);
            } catch (error) {
                setIsMotionRunning(false);
                if (typeof error.respone !== 'undefined') {
                    if (error.response.status === 403) {
                        showMessage('Authentication failed.');
                        navigate('/login')
                    }
                }
            }
        }
        motionStatus();
    }, [updateStates, navigate, refreshState])

    const sendArmRequest = async () => {
        setUpdateStates(false);
        setExecuting(true);
        try {
            const respone = await armCamera();
            if (respone) {
                setUpdateStates(true);
            }
        } catch (error) {
            if (error.response.status === 403) {
                showMessage('Authentication failed.');
                navigate('/login')
            }
        }
        setExecuting(false);
    }

    const sendDisarmRequest = async () => {
        setUpdateStates(false);
        setExecuting(true);
        const respone = await disarmCamera();
        if (respone) {
            await sleep(5000);
            setUpdateStates(true);
            console.log('exec done', updateStates);
        }
        setExecuting(false);
    }

    const refreshStateClicked = () => {
        if (refreshState === false) {
            setRefreshState(true);
        } else {
            setRefreshState(false);
        }
    }

    const checkWifiClicked  = async () => {
        const response = await checkWifiAndSendRequest();
        if (response) {
            showMessage("Connected to " + response);
        } else {
            showMessage("Error checking WiFi status.")
        }
    }

    const sendTestPush  = async () => {
        const response = await apiClient.post('/api/testpush', {
            message: 'This is a test push',
        });
        if (response.status === 200) {
            showMessage("Notification Send Successfully");
        } else {
            showMessage("Error sending test notification.")
        }
    }

    return (
        <>
            <h1>Server Status</h1>
            <dl>
                <dt className="col-lg-4 col-md-4 col-sm-4 col-12">Server Status:</dt>
                <dd className="col-lg-4 col-md-4 col-sm-4 col-12">{serverStatus ? ("Connected") : ("No Connection")}</dd>
            </dl>
            <h1>Camera Details</h1>
            <h2>Selected Camera</h2>
            <dl>
                <dt className="col-lg-4 col-md-4 col-sm-4 col-12">Camera Name</dt>
                {!loading ? (<dd className="col-lg-4 col-md-4 col-sm-3 col-12">{cameraData.camera_name}</dd>) : (<dd>No Connection...</dd>)}
                <dd className="col-lg-4 col-md-4 col-sm-4 col-12">
                    <Link to={settingLink}><SettingIcon width="24" height="24"/></Link>
                </dd>
            </dl>
            <dl>
                <dt className="col-lg-4 col-md-4 col-sm-4 col-12">Camera is accessible:</dt>
                <dd className="col-lg-4 col-md-4 col-sm-4 col-12">{isCameraReady ? ("Yes") : ("No")}</dd>
                <dd className="col-lg-4 col-md-4 col-sm-4 col-12">
                    <button className='inpage-link-button' onClick={refreshStateClicked }>
                            <RefreshIcon  width="24" height="24" />
                    </button>
                </dd>
            </dl>
            <dl>
                <dt className="col-lg-4 col-md-4 col-sm-4 col-12">Camera is armed:</dt>
                <dd className="col-lg-4 col-md-4 col-sm-4 col-12">{isMotionRunning ? ("Yes") : ("No")}</dd>
            </dl>
            <dl className="col-12">
            {isMotionRunning ?
                (<button
                    onClick={() => sendDisarmRequest()}
                    disabled={executing}
                    >Disarm Camera</button>)
                : (<button
                onClick={() => sendArmRequest()}
                disabled={executing || !serverStatus}
                >Arm Camera</button>)}
            </dl>
            <dl className="col-12">
                <button
                    onClick={() =>  navigate(streamUrl)}
                    disabled={loading || !isCameraReady || !isMotionRunning}
                >View Live Stream</button>
            </dl>
            <dl className="col-12">
                <button
                    onClick={() =>  checkWifiClicked()}
                >Check WiFi</button>
            </dl>
            <dl className="col-12">
                <button
                    onClick={() =>  sendTestPush()}
                >Reuest Test Push Notification</button>
            </dl>

        </>
    )   
}
