import apiClient from '../util/axiosInstance';
import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import move2Arm from "../util/move2Arm";
import move2Disarm from "../util/move2Disarm";
import sleep from "../util/sleep";
import '../util/stream.css';
import { FullScreenIcon } from "../svg/fullScreenIcon.js";
import { useMessage } from "../Components/MessageContext.js";

export const StreamPage = () => {
    const { cameraId } = useParams();
    const [cameraData, setCameraData] = useState([]);
    const [settingLink, setSettingLink] = useState('/setting');
    const [loading, setLoading] = useState(true);
    const [isCameraReady, setIsCameraReady] = useState(false);
    const [isMotionRunning, setIsMotionRunning] = useState(false);
    const [executing, setExecuting] = useState(false);
    const [streamUrl, setStreamUrl] = useState('');
    const [streamLoading, setStreamLoading] = useState(true);
    const [refreshState, setRefreshState] = useState(false);
    const [serverStatus, setServerStatus] = useState(false);
    
    const controllerRef = useRef(''); // Using ref to persist value across renders
    const imgRef = useRef(null);

    const { showMessage } = useMessage();
    const navigate = useNavigate();

    useEffect( () => {
        const fetchServerStatus = async () => {
            try {
                const respone = await apiClient.get('/api/test');
                if (respone.status === 200) {
                    setServerStatus(true);
                } else {
                    setServerStatus(false);
                }
            } catch {
                setServerStatus(false);
                cameraData.camera_name = 'No connection';
            }
        }
        fetchServerStatus();
    }, [refreshState]);


    useEffect( () => {
        const loadCameraData = async () => {
            try {
                const response = await apiClient.get(`/api/getcamerabyid/${cameraId }`);
                const cameraData = await response.data;
                setCameraData(cameraData);      
                const path = `/setting/${cameraData.camera_id}`;
                setSettingLink(path);
                setLoading(false);
            } catch (error) {
                if (typeof error.respone !== 'undefined') {
                    if (error.response.status === 403) {
                        showMessage('Authentication failed.');
                        navigate('/login')
                    }
                }
                showMessage('Error connecting the camera.');
                return false;
            }
        }
        loadCameraData();
    }, [cameraId, navigate]);

    useEffect( () => {
        const cameraStatus = async () => {
            try {
                const response = await apiClient.get('/api/iscameraready');
                const status = response.data;
                setIsCameraReady(status);
            } catch (error) {
                if (typeof error.respone !== 'undefined') {
                    if (error.response.status === 403) {
                        showMessage('Authentication failed.');
                        navigate('/login')
                    }
                }
                showMessage('Camera status unknown');
            }
        }
        cameraStatus();
    }, [navigate]);


    useEffect(() => {
        const controller = new AbortController();

        const getStreamUrl = async () => {
            let streamParam = '';
            try {
                // Create a new AbortController for the request
                controllerRef.current = controller;
                const response = await apiClient.get('/api/getstreamurl', {
                    signal: controller.signal,
                });

                streamParam = response.data;

                const startResponse = await apiClient.post('/api/startstream', {
                    stramUrl: streamParam,
                });
                if (startResponse.status === '200') {
                    console.log('start response recevied');
                }

                const streamPath = `https://eva.arashbox.com/api/stream?streamurl=${streamParam}`;
                setStreamUrl(streamPath);
                setStreamLoading(false);
                
            } catch (error) {
                if (apiClient.isCancel(error)) {
                    console.log('Stream request canceled');
                } else {
                    console.error('Error fetching stream:', error);
                }
            }
        };
        getStreamUrl();
        return () => {

            console.log('return path');
            const stopResponse = apiClient.post('/api/stopstream', {
                streamUrl: streamUrl,
            });
            if (stopResponse.status === '200') {
                console.log('stop signal received');
            }
            if (controllerRef.current) {
                console.log('Aborting stream request');
                controllerRef.current.abort();
                setStreamUrl(''); // Reset the image source to stop the stream
    
                if (imgRef.current) {
                    imgRef.current.remove();  // Completely remove the <img> element
                }
                setStreamLoading(true);
            }
        };
    }, []);

    useEffect( () => {
        const motionStatus = async () => {
            try {
                const response = await apiClient.get('/api/getmotionstatus');
                const status = response.data;
                setIsMotionRunning(status);
            } catch {
                showMessage('Error conneting to server.');
            }
        }
        motionStatus();
    }, [])

    const requestMove2Arm = async () => {
        setExecuting(true);
        await move2Arm();
        sleep(4000);
        setExecuting(false);
    }

    const requestMove2Disarm = async () => {
        setExecuting(true);
        await move2Disarm();
        sleep(4000);
        setExecuting(false);
    }

    const handleFullscreen = () => {
        if (imgRef.current) {
          // Check if Fullscreen API is available
          if (imgRef.current.requestFullscreen) {
            imgRef.current.requestFullscreen();
          } else if (imgRef.current.mozRequestFullScreen) {
            // For Firefox
            imgRef.current.mozRequestFullScreen();
          } else if (imgRef.current.webkitRequestFullscreen) {
            // For Chrome, Safari, and Opera
            imgRef.current.webkitRequestFullscreen();
          } else if (imgRef.current.msRequestFullscreen) {
            // For IE/Edge
            imgRef.current.msRequestFullscreen();
          }
        }
    }   

    const stopStream = () => {
        setStreamUrl('');
        imgRef.current.remove();
        setStreamLoading(true);
        const stopResponse = apiClient.post('/api/stopstream', {
            streamUrl: '123',
        });
        if (stopResponse.status === '200') {
            console.log('stop signal received');
        }

    }

    return (
        <>
            <h1>Camera View</h1>
            <h2>Selected Camera</h2>
            <dl>
                <dt className="col-lg-4 col-md-4 col-sm-4 col-12">Camera Name</dt>
                {!loading ? (<dd className="col-lg-4 col-md-4 col-sm-3 col-12">{cameraData.camera_name}</dd>) : (<dd>No Connection...</dd>)}
                <dd className="col-lg-4 col-md-4 col-sm-4 col-12"><Link to={settingLink}>Settings</Link></dd>
            </dl>
            {loading && (
                <p>Loading stream...</p>  // Display a loading message while the stream is being fetched
            )}
            {isCameraReady && isMotionRunning && !streamLoading ? 
                ( <div className="stream">  
                    <img  alt="Live Stream" src={streamUrl} style={{ width: '100%', height:'auto' }} className="image"   key={streamUrl} ref={imgRef} />
                    <button className="fullscreen-button" onClick={handleFullscreen}  style={{ width: '50px' }}>
                                    <FullScreenIcon width="24" height="24"/>
                    </button>
                    {/* <video ref={videoRef} controls width="600" /> */}

                    </div> ) : ("")}
            {!isCameraReady ? (<p>Camera not connected</p>) : ""}
            {!isMotionRunning ? (<p>Camera is not armed!</p>) : ""}
            
            <dl>
                <dd className="col-12">
                    <button 
                        disabled={executing|| !serverStatus}
                        onClick={() => requestMove2Arm()}
                        >Move to Arm Position
                    </button>
                </dd>
            </dl>
            <dl>
                <dd className="col-12">
                    <button 
                        disabled={executing || !serverStatus }
                        onClick={() => requestMove2Disarm()}
                        >Move to Disarm Position
                    </button>
                </dd>
            </dl>

            <dl>
                <dd className='col-12'>
                    <button 
                        onClick={() => stopStream()}
                        disabled={!serverStatus}
                    >Stop the Stream</button>
                </dd>
            </dl>
        </>
    )
}
