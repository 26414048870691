import Header from './Components/Header';
import Navbar from './Components/Navbar';
import { WebSiteRoutes } from './WebSiteRoutes';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './stylesheet.css';
import './MediaStyle.css';
import { useState, useEffect } from 'react';
import getUser from './auth/getUser';
import { MessageProvider } from './Components/MessageContext.js';
import { GlobalMessage } from './Components/GlobalMessage.js';
import { Preferences } from '@capacitor/preferences';
import setupWifiNotifier from './util/wifiNotofier.js';
import registerPushNotifications from './util/pushNotification.js';

export const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState( async () => await getUser());
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);

    const toggleSidebar = () => {   
        setIsSidebarVisible(!isSidebarVisible);
    };

    useEffect( () => {
        const setInitialUser = async () => {
            async function getAuthToken() {
                const { value: token } = await Preferences.get({ key: 'authToken' });
                return token;
            }
            const    token = await getAuthToken();
            if (token) {
                const decodedUser = getUser();  // Decode the user from the token
                if (decodedUser && decodedUser !== user) {  // Only update state if the user changes
                    setIsLoggedIn(true);
                    setUser(decodedUser);  // Update the user state
                }
            }
        }
    setInitialUser();
    }, []);  // Only run on mount   

    const handleLogin = async (token) => {
        await Preferences.set({
            key: 'authToken',
            value: token
        });
        setUser(getUser);
        setIsLoggedIn(true);
    };

    const handleLogout = async () => {
        await Preferences.remove({ key: 'authToken' });

        setIsLoggedIn(false);
        setUser('');
    };

    useEffect(() => {
        setupWifiNotifier();
    }, []);

    useEffect( ()=> {
        if (typeof user.id !== "undefined") {
            registerPushNotifications();
        }
    }, [user])


    return (
        <Router>
        <div className="App">
        <MessageProvider>
            <Header />
            <GlobalMessage />  {/* Show the message on every page */}
            <div className="">
                <div className="row">
                    {/* Sidebar (3 columns) */}
                        <div className={`${isSidebarVisible ? 'col-2' : 'col-1'} transition-width `} id="sidebar">
                            <Navbar isLoggedIn={isLoggedIn}
                                onLogout={handleLogout} 
                                user={user} 
                                isSidebarVisible={isSidebarVisible}
                                toggleSidebar={toggleSidebar}/>
                        </div>
                    
                    {/* Main Content (9 columns) */}
                    <div className={`${isSidebarVisible ? 'col-10' : 'col-11'} transition-width`}>
                        <div id="contents" className='col-11 flex-grow-1'>
                            <WebSiteRoutes onLogin={handleLogin} user={user} />
                        </div>
                    </div>
                </div>
            </div>
        </MessageProvider>
        </div>
        </Router>

    );
}
