import apiClient from '../util/axiosInstance';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { DeleteIcon } from "../svg/deleteIcon";
import { useMessage } from "../Components/MessageContext.js";
import { EditIcon } from "../svg/editIcon.js";

export const DeviceList = () => {
    const [deviceData, setDeviceData] = useState([]);
    const [loading, setLoading] = useState(true); // State to show loading status

    const navigate = useNavigate();
    const { showMessage } = useMessage();

    const getDeviceData = async () => {
        try {
            const respone = await apiClient.get('/api/getallclients');
            setDeviceData(respone.data);  
        } catch (error) {
            if (error.response.status === 403) {
                showMessage('Authentication failed.');
                navigate('/login');
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDeviceData();
    }, [navigate]);

    const deleteDevice = async (client_id) => {
        try {
            await apiClient.delete(`/api/deletedevicebyid/${client_id}`);
            showMessage('Device deleted successfully!');
            getDeviceData();
        } catch {
            showMessage('Error deleting the device!');
        }
    };

    return (
        <>
            <h2>Device List</h2>
            {loading ? (<p>Loading Data...</p>) : (
            <div>
            <p>{deviceData.client_ip}</p>
            <table border="1">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Device IP</th>
                        <th>Device Name</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {deviceData.map((item) => (
                        <tr key={item.client_id}>
                            <td>{item.client_id}</td>
                            <td>{item.client_ip}</td>
                            <td>{item.client_name}</td>
                            <td>
                                <button 
                                    className='inline-link-button' 
                                    onClick={() => navigate(`/editdevice/${item.client_id}`)}>
                                    <EditIcon width="18" height="18"/>
                                </button>
                            </td>
                            <td>
                                <button 
                                    className='inline-link-button' 
                                    onClick={() => deleteDevice(item.client_id)}>
                                    <DeleteIcon width="18" height="18"/>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <dl>
                <dd className="col-12">
                    <button onClick={() => navigate('/adddevice')}>Add a New Device</button>
                </dd>
            </dl>

            </div>
            )}
      </>
    )
}
