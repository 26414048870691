import { createContext, useState, useContext } from 'react';

// Create a context
const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
    const [message, setMessage] = useState(null);

    const showMessage = (messageContent) => {
        setMessage(messageContent);
        // Automatically clear the message after 3 seconds
        setTimeout(() => {
            setMessage(null);
        }, 3000);
    };

    return (
        <MessageContext.Provider value={{ message, showMessage }}>
            {children}
        </MessageContext.Provider>
    );
};

// Custom hook to use the message context
export const useMessage = () => {
    return useContext(MessageContext);
};
