import apiClient from '../util/axiosInstance';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cameraDataProhibitedFields, cameraDataLabels } from '../util/Variables.js';
import { useMessage } from "../Components/MessageContext.js";

const Setting = ( ) => {
    const { cameraId } = useParams();
    const camera_id = decodeURIComponent(cameraId);

    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const { showMessage } = useMessage();

    useEffect(  () => {
        const loadCameraDataByID = async (camera_id) => {
            const response = await apiClient.get(`/api/getcamerabyid/${encodeURIComponent(camera_id)}`);
            const cameraData = await response.data; 

            setFormData(cameraData);
            setIsLoading(false);
        }
        loadCameraDataByID(camera_id);
    }, [camera_id])

    const handleChange = (event, key) => {
        const { value } = event.target;
        setFormData((prevData) => ({
           ...prevData,
            [key]: value,
        }));
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const cameraData = formData;
        try {
            const response = await apiClient.post(`/api/updatecameradata`, cameraData);
            setFormData(response.data);
        } catch (error) {
            showMessage("Error updating camera data:", error);
        }
    };
    
    return (
        <>
            <h1>Camera Setting For {formData.camera_name || 'not found'}</h1>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <form onSubmit={handleSubmit}>
                {Object.keys(formData).map((key) => {
                    if (cameraDataProhibitedFields.includes(key)) {
                    return null; // Skip this field
                    }
                    const inputType = key.toLowerCase().includes("password")
                    ? "password"
                    : "text";

                    const label = cameraDataLabels[key] || key;

                    return (
                    <div key={key}>
                        <dl>
                            <dt className="col-lg-5 col-md-5 col-sm-6 col-12"><label htmlFor={key}>{label}</label></dt>
                            <dd className="col-lg-7 col-md-7     col-sm-6 col-12">
                                <input
                                    type={inputType} // Dynamically set input type based on key
                                    id={key}
                                    value={formData[key]}
                                    onChange={(event) => handleChange(event, key)}
                                />
                            </dd>
                        </dl>
                    </div>
                    );
                })}
                <dl className="col-12">
                    <button type="submit">Save Changes</button>
                </dl>
                </form>
            )}
        </>
    )
}

export default Setting; 