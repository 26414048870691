import apiClient from './axiosInstance';

const move2Arm = async () => {
    try {
    const response = await apiClient.get('/api/move2arm');
    if (response.ok) {
        return true;
    } else {
        return false;
    }
    
    } catch (err) {
        console.log(err);
        return false;
    }
}

export default move2Arm;