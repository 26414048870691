const getPayloadFromToken = (token) => {
    if (!token || token.split('.').length < 3) {
        return null; // Token format is invalid, return null early
    }

    try {
        const encodedPayload = token.split('.')[1];
        const base64 = encodedPayload.replace(/-/g, '+').replace(/_/g, '/');
        const padded = base64.padEnd(base64.length + (4 - (base64.length % 4)) % 4, '=');
        const binary = window.atob(padded);
        const binaryArray = Uint8Array.from(binary, (char) => char.charCodeAt(0));
        const decodedPayload = JSON.parse(new TextDecoder().decode(binaryArray));
        return decodedPayload
    } catch (error) {
        console.error("Error decoding token payload", error);
        return null;
    }
};

export default getPayloadFromToken;