import apiClient from "./axiosInstance";


const checkServerStatus = async () => {
    try {
        const respone = await apiClient.get('/api/test');
        if (respone.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch {
        return false;
    }
}

export default checkServerStatus;