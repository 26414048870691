import apiClient from "../util/axiosInstance";
import { RefreshIcon } from "../svg/refreshIcon";
import { useNavigate } from "react-router-dom";


const ServerNotAvailable = ({ serverStatus, setServerStatus }) => {
    const navigate = useNavigate('/');

    const fetchServerStatus = async () => {
        try {
            const respone = await apiClient.get('/api/test');
            if (respone.status === 200) {
                setServerStatus(true);
                navigate('/');
            } else {
                setServerStatus(false);
            }
        } catch {
            setServerStatus(false);
        }
    }

    const refreshStateClicked = () => {
        fetchServerStatus();
    }

    return (
        <>
        <h1>Server Not Available!</h1>
        <dl>
            <dt className="col-lg-4 col-md-4 col-sm-4 col-12">Refresh Status:</dt>
            <dd className="col-lg-4 col-md-4 col-sm-4 col-12">{serverStatus ? ("Connected") : ("No Connection")}</dd>
            <dd className="col-lg-4 col-md-4 col-sm-4 col-12">
                <button className='inpage-link-button' onClick={refreshStateClicked }>
                        <RefreshIcon  width="24" height="24" />
                </button>
        </dd>

        </dl>
        </>
    )
}
export default ServerNotAvailable;

