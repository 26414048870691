import React, { useState, useEffect } from 'react';
import './ErrorMessage.css';  // External CSS for styling

const ErrorMessage = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();  // Close the error after 3 seconds
    }, 5000);

    return () => clearTimeout(timer);  // Clean up the timer on unmount
  }, [onClose]);

  return (
    <div className="error-message">
      {message}
    </div>
  );
};

export default ErrorMessage;
