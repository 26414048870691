import axios from 'axios';
import { Preferences } from '@capacitor/preferences';

// Create an Axios instance
const apiClient = axios.create({
    baseURL: 'https://eva.arashbox.com', // Set your server URL here
    headers: {
        'Content-Type': 'application/json',
    },
    // withCredentials: true,  // Make sure credentials are sent with the requests
});

// Axios request interceptor to automatically add the token to the headers
apiClient.interceptors.request.use(async (config) => {
    try {
        // Get the token from Capacitor Preferences
        const { value: token } = await Preferences.get({ key: 'authToken' });

        // If the token exists, set it in the Authorization header
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
    } catch (error) {
        console.error("Error retrieving token", error);
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default apiClient;
