import { PushNotifications } from '@capacitor/push-notifications';
import apiClient from './axiosInstance';

const registerPushNotifications = async () => {
    // Request permission to use Push Notifications
    let permStatus = await PushNotifications.requestPermissions();

    if (permStatus.receive === 'granted') {
        // Register with the FCM service to get a token
        await PushNotifications.register();
    }

    // Event handler when a push notification registration is successful
    PushNotifications.addListener('registration', async (token) => {
        console.log('Push registration success:', token.value);
        // Send this token to your server to store for future notifications

        // Send the token to your backend
        try {
            await apiClient.post('/api/registerdevice', {
                token: token.value,  // Send the device token
            });
            console.log('Token sent to backend successfully');
        } catch (error) {
            console.error('Error sending token to backend:', error);
        }
    });

    // Handle incoming notifications when the app is foregrounded
    PushNotifications.addListener('pushNotificationReceived', (notification) => {
        console.log('Push received:', notification);
        // Trigger the function based on the notification data
        if (notification.data?.key1 === 'value1') {
            // Run specific logic
        }
    });

    // Handle notification taps when the app is backgrounded
    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        console.log('Notification tapped:', notification);
        // Handle tap action here
    });
};

export default registerPushNotifications();
