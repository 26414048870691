const Header = () => {
    return (
        <>
            <header>
                <h1>EVA CAM</h1>
            </header>
        </>
    )
}

export default Header;