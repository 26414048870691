import apiClient from './axiosInstance';
import { WifiPlugin } from '../plugins/wifi-plugin/index.ts';


const checkWifiAndSendRequest = async () => {
    try {
        // const response = await WifiPlugin.wifitest('hi');
        // console.log(response);
        const { ssid } = await WifiPlugin.getSSID();
        console.log('Connected Wi-Fi SSID:', ssid);

        // if (ssid === 'my_wifi') {
        //   // Send POST request to your backend
        //     await apiClient.post('/api/network-connect', {
        //         ssid: ssid,
        //     });
        //     console.log('POST request sent');
        // } else {
        //     console.log('Not connected to the target network.');
        // }
        return ssid;
    } catch (error) {
        console.error('Error getting Wi-Fi SSID:', error);
        return false;
    }
};

export default checkWifiAndSendRequest;