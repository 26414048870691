import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DarkModeIcon } from '../svg/darkModeIcon';
import { LightModeIcon } from '../svg/lightModeIcon';
import { LoginIcon } from '../svg/loginIcon';
import { LogoutIcon } from '../svg/logoutIcon';
import { CameraIcon } from '../svg/cameraIcon';
import { DeviceIcon } from '../svg/deviceIcon';
import { HomeIcon } from '../svg/homeIcon'; 
import apiClient from '../util/axiosInstance';
import { MoveLeftIcon } from '../svg/moveLeftIcon';
import { MoveRightIcon } from '../svg/moveRightIcon';
import { ProfileIcon } from '../svg/profileIcon';
import ServerCheckLink from '../util/serverCheckLink';

const Navbar = ({ isLoggedIn, onLogout, user, isSidebarVisible, toggleSidebar, serverStatus, setServerStatus }) => {
    let initMode = 'dark';
    const [darkMode, setDarkMode] = useState(initMode);
    const [linkClass , setLinkClass] = useState("icon transition-large")

    useEffect( () => {
        const setLinkClassValue = () => {
            if (isSidebarVisible) {
                return "icon transition-large"
            } else {
                return "icon-enlarged transition-large"
            }
        }
        setLinkClass(setLinkClassValue());
    }, [isSidebarVisible])

    

    useEffect(() => {
        if (darkMode === 'dark') {
            document.body.classList.add('dark');
        } else {
          document.body.classList.remove('dark');
        }
      }, [darkMode]);

    useEffect( () => {
        if (user) {
            setDarkMode(user.darkMode);
        }
    }, [user])

    const toggleDarkMode = async () => {
        let newMode = 'dark';
        if (darkMode === 'dark') {
            newMode = 'light';
        }
        setDarkMode(newMode);
        document.body.classList.toggle('dark', newMode);
        try {
            await apiClient.post('/api/setdarkmode', {
                darkMode: newMode,
            });
        } catch {

        }
      }

    return (
        <>
            <nav className='navbar-vertical transition-width'>
                <div className='sidebar-div'>
                    <button className='dark-mode-button' onClick={toggleDarkMode}>
                            {(darkMode !== 'dark') ? (<DarkModeIcon  width="24" height="24"/>) : (<LightModeIcon  width="24" height="24"/>)}
                    </button>
                    <button className='sidebar-button'
                    onClick={toggleSidebar}  >
                    {isSidebarVisible ? (<MoveLeftIcon className="icon"/>) : (<MoveRightIcon className="icon" />)}
                </button>
                </div>
                <ul>
                    {isLoggedIn ? ( 
                    <>
                    <li>
                        <Link to="/" >
                            <HomeIcon className={linkClass}/>{isSidebarVisible ? (<span>&nbsp;Home</span>) : ('')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/setting/2" >
                            <CameraIcon className={linkClass} />{isSidebarVisible ? (<span>&nbsp;Camera Setting</span>) : ('')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/devices" >
                            <DeviceIcon className={linkClass} />{isSidebarVisible ? (<span>&nbsp;Devices</span>) : ('')}&nbsp;
                        </Link>
                    </li>
                    <li>
                        <Link to="/profile" >
                            <ProfileIcon className={linkClass} />{isSidebarVisible ? (<span>&nbsp;Profile</span>) : ('')}&nbsp;
                        </Link>
                    </li>
                    <li>
                        <Link to="/" onClick={onLogout} >
                            <LogoutIcon className={linkClass} />{isSidebarVisible ? (<span>&nbsp;Logout</span>) : ('')}&nbsp;
                        </Link>
                    </li>
                    </>
                    ) : (
                    <li>
                        <Link className='split' to="/login" >
                            <LoginIcon className={linkClass} />{isSidebarVisible ? (<span>&nbsp;Login</span>) : ('')}
                        </Link>
                    </li>
                    )}
                    
                    {/* <li>
                        <Link to="/addcamera">Add Camera</Link>
                    </li>
                    <li>    
                    <Link to="/adddevice">Add Device</Link>
                    </li> */}
                </ul>
            </nav>
        </>
    )
}

export default Navbar;  